.categoryTitleText{
    background-color: white;
    border-radius: 10px;
    padding: 8px;
    color:#000;
    font-size: 1.2rem;   
    
}
.cardapio {
    margin-bottom: 20px;
}

.categoryTitle{
    text-shadow: 7px 5px 3px rgba(114,186,22,0.6);
    margin: 10px;
    margin-top: 60px
    
}

.listaDeProdutos {
    margin-top: 5px;
    color: rgb(9, 255, 0);
    background-color: #000;
    border-bottom: 1px solid rgb(89, 89, 89);
}