html, body, body > div, .app {
  /* height: 100%; */
  color: rgb(0, 136, 255);
}
.App {

  text-align: center;
  background-color: #000;
  width: 100%;
  height: 100%;
  margin-top: auto;
  height: 100%;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.footer {
  margin-top: 90%;
  color:rgb(0, 136, 255);
  padding-bottom: 25%;
  background-color: #000;
}